import Box from '@mui/material/Box';
import isEqual from 'lodash/isEqual';
import React, { memo } from 'react';
import { Container, Typography } from '@mui/material';
import Header from '../../components/Header';

const NotFound: React.FunctionComponent = () => {
    return (
        <Box>
            <Header />
            <Container maxWidth={'md'} sx={{ padding: '0 4em' }}>
                <Typography
                    sx={{
                        fontSize: '2.5rem',
                        fontWeight: 400,
                        textAlign: 'center',
                    }}
                    pt={4}
                    gutterBottom
                    variant="h1"
                >
                    404
                </Typography>
                <Typography
                    sx={{
                        fontSize: '1.5rem',
                        fontWeight: 400,
                        textAlign: 'center',
                    }}
                    gutterBottom
                >
                    Error - Page Not Found
                </Typography>
                <Typography
                    sx={{
                        fontSize: '1.5rem',
                        fontWeight: 400,
                        textAlign: 'center',
                    }}
                    pt={4}
                    gutterBottom
                >
                    Please check the url
                </Typography>
            </Container>
        </Box>
    );
};

export default memo(NotFound, isEqual);
