import { useMatch } from 'react-router-dom';
import { atom, useRecoilState, useRecoilValue } from 'recoil';

const showFilterState = atom({
    default: window.innerWidth >= 980,
    key: 'showFilterState',
});

const showSearchState = atom({
    default: true,
    key: 'showSearchState',
});

export const useShowFilter = () => useRecoilValue(showFilterState);
export const useShowSearch = () => useRecoilValue(showSearchState);

export const useToggleFilter = () => {
    const [showFilter, setShowFilter] = useRecoilState(showFilterState);
    return async () => {
        setShowFilter(!showFilter);
    };
};

export const useToggleSearch = () => {
    const [showSearch, setShowSearch] = useRecoilState(showSearchState);
    return async () => {
        setShowSearch(!showSearch);
    };
};

export const usePresented = () => {
    const showFilter = useShowFilter();
    const showSearch = useShowSearch();
    const tenderId = useMatch('tender/:id')?.params.id;
    return showFilter
        ? showSearch
            ? tenderId
                ? { filter: true, search: true, view: true }
                : { filter: true, search: true, view: false }
            : tenderId
            ? { filter: false, search: false, view: true }
            : { filter: true, search: true, view: false }
        : showSearch
        ? tenderId
            ? { filter: false, search: true, view: true }
            : { filter: false, search: true, view: false }
        : tenderId
        ? { filter: false, search: false, view: true }
        : { filter: false, search: true, view: false };
};
