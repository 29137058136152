import { memo } from 'react';
import { Box, Link } from '@mui/material';
import TenderItem from './TenderItem';
import TendersLoading from './TendersLoading';
import Loading from '../../components/Loading';
import isEqual from 'lodash/isEqual';
import { useTenders } from '../../services/TendersService';
import { Tender } from '@codeaim/tender-ni-api';

const TenderList: React.FunctionComponent = () => {
    const { tenders, getNext, next, loading, complete } = useTenders();
    return tenders ? (
        <Box
            sx={{ overflow: 'auto' }}
            onScroll={(event) => {
                if (!complete && !loading && event.currentTarget.scrollTop >= event.currentTarget.scrollHeight - event.currentTarget.offsetHeight - 20) {
                    getNext();
                }
            }}
        >
            {tenders.map((tender: Tender) => (
                <TenderItem key={tender.id} tender={tender} />
            ))}
            {!loading && complete && tenders.length === 0 && (
                <Box
                    sx={{
                        fontSize: (t) => t.spacing(3),
                        padding: (t) => t.spacing(2),
                        textAlign: 'center',
                    }}
                >
                    No matching tenders
                </Box>
            )}
            {next && (
                <Box component={Link} sx={{ display: 'none' }} href={`/next/${next}${window.location.search}`}>
                    More tenders
                </Box>
            )}
            {loading && <TendersLoading />}
        </Box>
    ) : (
        <Loading />
    );
};

export default memo(TenderList, isEqual);
