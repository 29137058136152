import { Box } from '@mui/material';
import isEqual from 'lodash/isEqual';
import React, { memo, Suspense } from 'react';
import { Helmet } from 'react-helmet';
import { Outlet } from 'react-router-dom';
import { helmetJsonLdProp } from 'react-schemaorg';
import { SearchAction, WebSite, Organization } from 'schema-dts';

import TenderSelect from '../features/TenderSelect';
import { usePresented } from '../services/NavigationService';
import Loading from './Loading';

import icon from '../assets/image/icon.png';
import twitterImage from '../assets/image/twitter.jpeg';

const TenderFilter = React.lazy(() => import('../features/TenderFilter'));

const Tenders: React.FunctionComponent = () => {
    const presented = usePresented();
    return (
        <Box sx={{ display: 'flex', flex: 1, minHeight: 0 }}>
            <Helmet
                script={[
                    helmetJsonLdProp<Organization>({
                        '@context': 'https://schema.org',
                        '@type': 'Organization',
                        name: 'Tender NI',
                        url: 'https://tender-ni.com',
                        logo: icon,
                        sameAs: ['https://www.facebook.com/tendernicom', 'https://twitter.com/tendernicom', 'https://www.linkedin.com/company/tender-northern-ireland'],
                    }),
                    helmetJsonLdProp<WebSite>({
                        '@context': 'https://schema.org',
                        '@type': 'WebSite',
                        name: 'Tenders Northern Ireland',
                        url: 'https://tender-ni.com',
                        potentialAction: {
                            '@type': 'SearchAction',
                            target: 'https://tender-ni.com/?search={search_term_string}',
                            'query-input': 'required name=search_term_string',
                        } as unknown as SearchAction,
                    }),
                ]}
            >
                <title>Tender Northern Ireland - Search Tenders Opportunities</title>
                <meta
                    name="description"
                    content="Search and find tender opportunities in Northern Ireland. View call for tenders from government departments and other organisations looking supplies, services and works across Northern Ireland."
                />
                <meta property="og:site_name" content="Tender Northern Ireland - Search Tenders Opportunities" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta property="fb:app_id" content="5348967695155569" />
                <meta name="twitter:site" content="@tendernicom" />
                <link rel="canonical" href="https://tender-ni.com" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Tender Northern Ireland - Search Tenders Opportunities" />
                <meta
                    property="og:description"
                    content="Search and find tender opportunities in Northern Ireland. View call for tenders from government departments and other organisations looking supplies, services and works across Northern Ireland."
                />
                <meta property="og:url" content="https://tender-ni.com" />
                <meta property="og:image" content={twitterImage} />
                <meta name="twitter:image:alt" content="Tender Northern Ireland twitter banner" />
            </Helmet>
            <Box
                sx={{
                    overflow: 'auto',
                    overflowX: 'hidden',
                    order: 3,
                    minHeight: '100%',
                    flex: 1,
                    background: (t) => t.palette.grey[200],
                    display: { xs: presented['view'] ? 'flex' : 'none', md: 'flex' },
                }}
            >
                <Outlet />
            </Box>
            <Box
                sx={{
                    overflow: 'auto',
                    maxWidth: { md: '56em' },
                    order: 2,
                    overflowX: 'hidden',
                    minHeight: '100%',
                    flex: 1,
                    display: {
                        xs: presented['view'] ? 'none' : presented['search'] ? 'flex' : 'none',
                        md: presented['search'] ? 'flex' : 'none',
                    },
                }}
            >
                <TenderSelect />
            </Box>
            {
                <Box
                    sx={{
                        overflow: 'auto',
                        overflowX: 'hidden',
                        order: 1,
                        minHeight: '100%',
                        flex: 1,
                        background: (t) => t.palette.grey[200],
                        maxWidth: '24em',
                        bottom: '3.5em',
                        top: '3.5em',
                        paddingBottom: '2em',
                        position: { xs: 'fixed', md: 'initial' },
                        overflowY: 'auto',
                        zIndex: 100,
                        display: presented['filter'] && navigator.userAgent != 'ReactSnap' ? 'flex' : 'none',
                    }}
                >
                    <Suspense fallback={<Loading />}>
                        <TenderFilter />
                    </Suspense>
                </Box>
            }
        </Box>
    );
};

export default memo(Tenders, isEqual);
