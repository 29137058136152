import isEqual from 'lodash/isEqual';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

type UrlState = [string[], (value: string, add: boolean) => void, (updated: string[]) => void];
type SingleUrlState = [string | undefined, (value: string | undefined) => void];
type RangeSingleUrlState = [[string, string] | undefined, (value: [string, string] | undefined) => void];

export function useUrlState(name: string): UrlState {
    const navigate = useNavigate();
    const current = useQueryParameter(name);
    const setValues = useCallback(
        (updated: string[]) => {
            const params = new URLSearchParams(window.location.search);
            params.delete(name);
            updated.forEach((value) => params.append(name, value));
            navigate({ search: params.toString() });
        },
        [current],
    );
    const setValue = useCallback(
        (value: string, add: boolean) => {
            const params = new URLSearchParams(window.location.search);
            const previous = params.getAll(name);
            params.delete(name);
            const updated = [...previous.filter((it) => it !== value), ...(add ? [value] : [])];
            updated.forEach((v) => params.append(name, v));
            navigate({ search: params.toString() });
        },
        [current],
    );
    return [current, setValue, setValues];
}

export function useUrlSingleState(name: string): SingleUrlState {
    const [urlState, , setValues] = useUrlState(name);
    return [urlState[0], (value) => setValues(value ? [value] : [])];
}

export function useRangeUrlSingleState(name: string): RangeSingleUrlState {
    const [urlState, setValues] = useUrlSingleState(name);
    const [start, end] = urlState ? urlState.split(':') : [undefined, undefined];
    return [start && end ? [start, end] : undefined, (value) => setValues(value ? `${value[0]}:${value[1]}` : undefined)];
}

export function useDateRangeUrlSingleState(name: string): RangeSingleUrlState {
    const [urlState, setValues] = useUrlSingleState(name);
    const [start, end] = urlState ? urlState.split('/') : [undefined, undefined];
    return [start && end ? [start, end] : undefined, (value) => setValues(value ? `${value[0]}/${value[1]}` : undefined)];
}

export const useQueryParameter = (name: string) => {
    const { search } = useLocation();
    const [values, setValues] = useState<string[]>(new URLSearchParams(search).getAll(name));
    useEffect(() => {
        const updated = new URLSearchParams(search).getAll(name);
        if (!isEqual(values, updated)) {
            setValues(updated);
        }
    }, [search]);
    return values;
};

export const useUpdateQueryParameter = (parameter: string) => {
    const navigate = useNavigate();
    const location = useLocation();
    return (values: string[]) => {
        const query = new URLSearchParams(location.search);
        query.delete(parameter);
        values.forEach((value) => query.append(parameter, value));
        navigate({ search: query.toString() });
    };
};
