import { createTheme, Shadows } from '@mui/material';
import * as colors from '@mui/material/colors';
import CssBaseline from '@mui/material/CssBaseline';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { RecoilRoot } from 'recoil';

import Main from './components/Main';
import TenderUnselected from './components/TenderUnselected';
import TenderView from './features/TenderView';
import About from './features/About';
import NotFound from './features/NotFound';
import { LicenseInfo, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterLuxon } from '@mui/x-date-pickers-pro/AdapterLuxon';
import 'typeface-nunito';
import { muiKey } from './Environment';

const theme = createTheme({
    shadows: Array(25).fill('none') as Shadows,
    typography: {
        fontFamily: ['Nunito', 'sans-serif'].join(','),
        fontSize: 16,
        button: {
            textTransform: 'none',
        },
    },
    palette: {
        primary: {
            main: colors.blue[800],
        },
    },
});

LicenseInfo.setLicenseKey(muiKey);

const App: React.FunctionComponent = () => {
    return (
        <React.StrictMode>
            <LocalizationProvider dateAdapter={AdapterLuxon} locale="en">
                <ThemeProvider theme={theme}>
                    <RecoilRoot>
                        <CssBaseline />
                        <Router>
                            <Routes>
                                <Route path="/" element={<Main />}>
                                    <Route index element={<TenderUnselected />} />
                                    <Route path="next/:id" element={<TenderUnselected />} />
                                    <Route path="tender/:id" element={<TenderView />} />
                                </Route>
                                <Route path="about" element={<About />} />
                                <Route path="*" element={<NotFound />} />
                            </Routes>
                        </Router>
                    </RecoilRoot>
                </ThemeProvider>
            </LocalizationProvider>
        </React.StrictMode>
    );
};

export default App;
