import { Box } from '@mui/material';
import isEqual from 'lodash/isEqual';
import React, { memo } from 'react';

import TenderList from './TenderList';
import TenderSearch from './TenderSearch';
import TenderSort from './TenderSort';

const TenderSelect: React.FunctionComponent = () => {
    return (
        <Box
            sx={{
                display: 'flex',
                flex: 1,
                flexDirection: 'column',
                borderRight: '1px solid #ddd',
            }}
        >
            <Box sx={{ display: 'flex' }}>
                <TenderSearch />
                <TenderSort />
            </Box>
            <TenderList />
        </Box>
    );
};

export default memo(TenderSelect, isEqual);
