import { apiBaseUrl } from '../Environment';
import axios from 'axios';

export const useContact = (reset: Function, setMessages: Function, setErrors: Function) => {
    return async (name: string, telephone: string, email: string, message: string) => {
        try {
            await axios.post(`${apiBaseUrl}/contact`, {
                name,
                telephone,
                email,
                message,
            });
            setMessages([{ key: 'contact-success', text: 'Thank you for reaching out' }]);
            setErrors([]);
            reset();
        } catch (error) {
            setMessages([]);
            setErrors([error]);
            reset();
        }
    };
};
