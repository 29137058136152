import {
    faAward,
    faBalanceScale,
    faBullhorn,
    faBusinessTime,
    faCalculator,
    faChalkboardTeacher,
    faCoins,
    faCommentAltDollar,
    faCommentLines,
    faComputerClassic,
    faDolly,
    faDollyFlatbedAlt,
    faDumpster,
    faList,
    faFileSearch,
    faFutbol,
    faHeartbeat,
    faLaptopCode,
    faLock,
    faLockOpen,
    faMailBulk,
    faMegaphone,
    faNewspaper,
    faPencilRuler,
    faPeopleArrows,
    faPhoneAlt,
    faPiggyBank,
    faPlane,
    faPoll,
    faPrint,
    faRedo,
    faRocket,
    faSendBack,
    faShieldCheck,
    faShip,
    faTasks,
    faTools,
    faTrain,
    faUtensilsAlt,
    faVacuum,
} from '@fortawesome/pro-light-svg-icons';
import { faCircle } from '@fortawesome/pro-thin-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from '@mui/material';
import * as colors from '@mui/material/colors';
import Typography from '@mui/material/Typography';
import isEqual from 'lodash/isEqual';
import { DateTime } from 'luxon';
import React, { memo, useEffect, useRef } from 'react';
import { NavLink, useLocation, useMatch } from 'react-router-dom';
import { JsonLd } from 'react-schemaorg';
import { JobPosting } from 'schema-dts';

import { useShowSearch } from '../../services/NavigationService';
import { Tender } from '@codeaim/tender-ni-api';

function getStatusColor(status: string) {
    switch (status) {
        case 'Awaiting Tender Opening': {
            return colors.yellow[800];
        }
        case 'Awarded': {
            return '#d4af37';
        }
        case 'Cancelled': {
            return colors.red[400];
        }
        case 'Established': {
            return colors.blue[400];
        }
        case 'Evaluation': {
            return colors.green[400];
        }
        case 'Tender Submission': {
            return colors.green[400];
        }
        default: {
            return colors.grey[400];
        }
    }
}

function getIcon(procedure: string) {
    switch (procedure) {
        case 'Accelerated open': {
            return faRocket;
        }
        case 'Competitive dialogue': {
            return faCommentLines;
        }
        case 'Competitive with negotiation': {
            return faCommentAltDollar;
        }
        case 'Dynamic Purchasing System': {
            return faComputerClassic;
        }
        case 'Involving negotiations': {
            return faCommentAltDollar;
        }
        case 'Negotiated with advertisement': {
            return faBullhorn;
        }
        case 'Negotiated with prior call for competition': {
            return faSendBack;
        }
        case 'Open': {
            return faLockOpen;
        }
        case 'Restricted': {
            return faLock;
        }
        default: {
            return faLockOpen;
        }
    }
}

export function getTenderIcon(status: string, procedure: string) {
    const title = `Status: ${status} \nProcedure: ${procedure}`;
    const color = getStatusColor(status);
    return (
        <span className="fa-stack fa-1x">
            <FontAwesomeIcon icon={faCircle} color={color} className="fa-stack-2x" title={title} />
            <FontAwesomeIcon icon={getIcon(procedure)} color={color} className="fa-stack-1x" title={title} />
        </span>
    );
}

function getCategoryIcon(category: string) {
    switch (category) {
        case 'Accounting, auditing and bookkeeping services': {
            return faCalculator;
        }
        case 'Advertising services': {
            return faMegaphone;
        }
        case 'Air transport services of passengers and freight, except transport of mail': {
            return faPlane;
        }
        case 'Architectural services; engineering services and integrated engineering services...': {
            return faPencilRuler;
        }
        case 'Building-cleaning services and property management services': {
            return faVacuum;
        }
        case 'Computer and related services': {
            return faLaptopCode;
        }
        case 'Education and vocational education services': {
            return faChalkboardTeacher;
        }
        case 'Financial services: (a) Insurance services, (b) Banking and investment services': {
            return faPiggyBank;
        }
        case 'Health and social services': {
            return faHeartbeat;
        }
        case 'Hotel and restaurant services': {
            return faUtensilsAlt;
        }
        case 'Investigation and security services, except armoured car services': {
            return faShieldCheck;
        }
        case 'Land transport services, including armoured car services and courier services, except transport of mail': {
            return faDollyFlatbedAlt;
        }
        case 'Legal services': {
            return faBalanceScale;
        }
        case 'Maintenance and repair services': {
            return faTools;
        }
        case 'Management consulting services and related services': {
            return faTasks;
        }
        case 'Market research and public opinion polling services': {
            return faPoll;
        }
        case 'Other services': {
            return faList;
        }
        case 'Personnel placement and supply services': {
            return faPeopleArrows;
        }
        case 'Publishing and printing services on a fee or contract basis': {
            return faPrint;
        }
        case 'Rail transport services': {
            return faTrain;
        }
        case 'Recreational, cultural and sporting services': {
            return faFutbol;
        }
        case 'Research and development services': {
            return faFileSearch;
        }
        case 'Sewage and refuse disposal services; sanitation and similar services': {
            return faDumpster;
        }
        case 'Supporting and auxiliary transport services': {
            return faDolly;
        }
        case 'Telecommunications services': {
            return faPhoneAlt;
        }
        case 'Transport of mail by land and by air': {
            return faMailBulk;
        }
        case 'Water transport services': {
            return faShip;
        }
        default: {
            return faList;
        }
    }
}

function getCategoryColor(category: string) {
    switch (category) {
        case 'Accounting, auditing and bookkeeping services': {
            return colors.green[600];
        }
        case 'Advertising services': {
            return colors.deepPurple[600];
        }
        case 'Air transport services of passengers and freight, except transport of mail': {
            return colors.lightBlue[600];
        }
        case 'Architectural services; engineering services and integrated engineering services...': {
            return colors.teal[600];
        }
        case 'Building-cleaning services and property management services': {
            return colors.yellow[700];
        }
        case 'Computer and related services': {
            return colors.blue[600];
        }
        case 'Education and vocational education services': {
            return colors.cyan[600];
        }
        case 'Financial services: (a) Insurance services, (b) Banking and investment services': {
            return colors.deepPurple[600];
        }
        case 'Health and social services': {
            return colors.red[400];
        }
        case 'Hotel and restaurant services': {
            return colors.indigo[600];
        }
        case 'Investigation and security services, except armoured car services': {
            return colors.blueGrey[600];
        }
        case 'Land transport services, including armoured car services and courier services, except transport of mail': {
            return colors.brown[400];
        }
        case 'Legal services': {
            return colors.cyan[800];
        }
        case 'Maintenance and repair services': {
            return colors.amber[600];
        }
        case 'Management consulting services and related services': {
            return colors.blueGrey[600];
        }
        case 'Market research and public opinion polling services': {
            return colors.pink[600];
        }
        case 'Other services': {
            return colors.grey[400];
        }
        case 'Personnel placement and supply services': {
            return colors.pink[800];
        }
        case 'Publishing and printing services on a fee or contract basis': {
            return colors.deepPurple[400];
        }
        case 'Rail transport services': {
            return colors.grey[700];
        }
        case 'Recreational, cultural and sporting services': {
            return colors.yellow[900];
        }
        case 'Research and development services': {
            return colors.deepOrange[600];
        }
        case 'Sewage and refuse disposal services; sanitation and similar services': {
            return colors.brown[500];
        }
        case 'Supporting and auxiliary transport services': {
            return colors.cyan[300];
        }
        case 'Telecommunications services': {
            return colors.lightBlue[800];
        }
        case 'Transport of mail by land and by air': {
            return colors.red[400];
        }
        case 'Water transport services': {
            return colors.blue[600];
        }
        default: {
            return colors.grey[400];
        }
    }
}

export function getCategoryBadge(category: string) {
    const title = `Category: ${category}`;
    const color = getCategoryColor(category);
    return (
        <span className="fa-stack fa-1x">
            <FontAwesomeIcon icon={faCircle} color={getCategoryColor(category)} className="fa-stack-2x" title={title} />
            <FontAwesomeIcon icon={getCategoryIcon(category)} color={color} className="fa-stack-1x" title={title} />
        </span>
    );
}

type TenderItemProps = {
    tender: Tender;
};

const TenderItem: React.FunctionComponent<TenderItemProps> = ({ tender }: TenderItemProps) => {
    const tenderItemRef = useRef<HTMLAnchorElement>(null);
    const showSearch = useShowSearch();
    const selectedTenderId = useMatch('/tender/:id')?.params.id;
    const { search } = useLocation();

    useEffect(() => {
        if (tender.id === Number(selectedTenderId)) {
            tenderItemRef.current?.scrollIntoView();
        }
    }, [showSearch]);

    return (
        <Box
            component={NavLink}
            to={`/tender/${tender.id}${search}`}
            ref={tenderItemRef}
            sx={{
                display: 'flex',
                alignItems: 'center',
                color: 'initial',
                borderColor: colors.grey[300],
                textDecoration: 'none',
                borderWidth: '0 0 1px 0',
                borderStyle: 'solid',
                padding: '1em',
                backgroundColor: (t) => (tender.id === Number(selectedTenderId) ? '#f2f4fa' : t.palette.background.paper),
                '&:hover': {
                    backgroundColor: (t) => (tender.id === Number(selectedTenderId) ? '#f2f4fa' : colors.grey[100]),
                },
            }}
            onClick={(event: any) => {
                if (tender.id === Number(selectedTenderId)) {
                    event.preventDefault();
                }
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    fontSize: '1.25em',
                    '& .fa-stack:not(:last-child)': {
                        marginBottom: '1em',
                    },
                }}
            >
                {getTenderIcon(tender.status, tender.procedure)}
                {tender.category && getCategoryBadge(tender.category)}
            </Box>
            <Box sx={{ paddingLeft: '1em' }}>
                <Typography
                    sx={{
                        paddingBottom: '0.5em',
                        '&.MuiTypography-h2': {
                            fontSize: '1em',
                            fontWeight: 600,
                            lineHeight: '1.6',
                        },
                    }}
                    variant="h2"
                >
                    {tender.title}
                </Typography>
                <Box
                    sx={{
                        paddingBottom: '0.5em',
                        fontStyle: 'italic',
                        fontSize: '0.85em',
                    }}
                >
                    {tender.type ? `${tender.type} - ` : ''}
                    {tender.ca}
                </Box>
                {tender.published && (
                    <Box
                        sx={{
                            paddingBottom: '0.5em',
                            fontStyle: 'italic',
                            fontSize: '0.85em',
                            '& span': {
                                paddingLeft: '0.5em',
                            },
                        }}
                        title={`Published: ${DateTime.fromISO(tender.published).toLocaleString(DateTime.DATETIME_FULL)}`}
                    >
                        <FontAwesomeIcon icon={faNewspaper} />
                        <span>{DateTime.fromISO(tender.published).toRelative()}</span>
                    </Box>
                )}
                {tender.deadline && (
                    <Box
                        sx={{
                            paddingBottom: '0.5em',
                            fontStyle: 'italic',
                            fontSize: '0.85em',
                            '& span': {
                                paddingLeft: '0.5em',
                            },
                        }}
                        title={`Deadline: ${DateTime.fromISO(tender.deadline).toLocaleString(DateTime.DATETIME_FULL)}`}
                    >
                        <FontAwesomeIcon icon={faBusinessTime} />
                        <Box
                            component="span"
                            sx={{
                                color: DateTime.fromISO(tender.deadline) < DateTime.local() ? colors.red[800] : 'initial',
                            }}
                        >
                            {DateTime.fromISO(tender.deadline).toRelative()}
                        </Box>
                    </Box>
                )}
                {tender.awarded && (
                    <Box
                        sx={{
                            paddingBottom: '0.5em',
                            fontStyle: 'italic',
                            fontSize: '0.85em',
                            '& span': {
                                paddingLeft: '0.5em',
                            },
                        }}
                        title={`Awarded: ${DateTime.fromISO(tender.awarded).toLocaleString(DateTime.DATETIME_FULL)}`}
                    >
                        <FontAwesomeIcon icon={faAward} />
                        <span>{DateTime.fromISO(tender.awarded).toRelative()}</span>
                    </Box>
                )}
                {tender.value !== 0 && (
                    <Box
                        sx={{
                            paddingBottom: '0.5em',
                            fontStyle: 'italic',
                            fontSize: '0.85em',
                            '& span': {
                                paddingLeft: '0.5em',
                            },
                        }}
                    >
                        <FontAwesomeIcon icon={faCoins} />
                        <span>{tender.value}</span>
                    </Box>
                )}
                {tender.cycle !== 1 && (
                    <Box
                        sx={{
                            paddingBottom: '0.5em',
                            fontStyle: 'italic',
                            fontSize: '0.85em',
                            '& span': {
                                paddingLeft: '0.5em',
                            },
                        }}
                    >
                        <FontAwesomeIcon icon={faRedo} />
                        <span>{tender.cycle}</span>
                    </Box>
                )}
                <Box sx={{ color: colors.grey[700] }}>{tender.descriptionTruncated}</Box>
            </Box>
            <JsonLd<JobPosting>
                item={{
                    '@context': 'https://schema.org',
                    '@type': 'JobPosting',
                    title: tender.title,
                    description: tender.descriptionTruncated,
                    datePosted: tender.published,
                    employmentType: tender.type,
                    hiringOrganization: {
                        '@type': 'Organization',
                        name: tender.ca,
                    },
                    ...(tender.contact && {
                        applicationContact: {
                            '@type': 'ContactPoint',
                            email: tender.contact,
                        },
                    }),
                    jobLocation: {
                        '@type': 'Place',
                        address: {
                            '@type': 'PostalAddress',
                            addressCountry: 'Northern Ireland',
                        },
                        branchCode: tender.nuts,
                    },
                    industry: tender.category,
                    validThrough: tender.deadline,
                    url: `https://tender-ni.com/tender/${tender.id}`,
                    estimatedSalary: tender.value,
                }}
            />
        </Box>
    );
};

export default memo(TenderItem, isEqual);
