import * as colors from '@mui/material/colors';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import isEqual from 'lodash/isEqual';
import React, { memo } from 'react';

import { useQueryParameter, useUpdateQueryParameter } from '../../services/QueryService';

const TenderSort: React.FunctionComponent = () => {
    const sort = useQueryParameter('sort')[0] ?? 'published:desc';
    const updateSort = useUpdateQueryParameter('sort');
    return (
        <div>
            <Select
                value={sort}
                displayEmpty
                fullWidth
                sx={{
                    height: '100%',
                    padding: '1em',
                    borderLeft: '1px solid',
                    borderColor: colors.grey[300],
                    '&.MuiInput-underline:before': {
                        borderColor: colors.grey[300],
                    },
                }}
                variant="standard"
                onChange={(event) => updateSort([event.target.value])}
            >
                <MenuItem
                    sx={{
                        display: 'flex',
                        padding: '0.25em 1em',
                        justifyContent: 'start',
                        '&.MuiListItem-root.Mui-disabled': {
                            fontWeight: '600',
                            display: 'flex',
                            padding: '0.5em',
                            opacity: '1',
                        },
                    }}
                    value=""
                    disabled
                >
                    Sort by
                </MenuItem>
                <MenuItem
                    sx={{
                        display: 'flex',
                        padding: '0.25em 1em',
                        justifyContent: 'start',
                    }}
                    value="ca:desc"
                >
                    Organisation
                </MenuItem>
                <MenuItem
                    sx={{
                        display: 'flex',
                        padding: '0.25em 1em',
                        justifyContent: 'start',
                    }}
                    value="ca:asc"
                >
                    Organisation Ascending
                </MenuItem>
                <MenuItem
                    sx={{
                        display: 'flex',
                        padding: '0.25em 1em',
                        justifyContent: 'start',
                    }}
                    value="awarded"
                >
                    Awarded
                </MenuItem>
                <MenuItem
                    sx={{
                        display: 'flex',
                        padding: '0.25em 1em',
                        justifyContent: 'start',
                    }}
                    value="awarded:asc"
                >
                    Awarded Ascending
                </MenuItem>
                <MenuItem
                    sx={{
                        display: 'flex',
                        padding: '0.25em 1em',
                        justifyContent: 'start',
                    }}
                    value="cycle:desc"
                >
                    Cycle
                </MenuItem>
                <MenuItem
                    sx={{
                        display: 'flex',
                        padding: '0.25em 1em',
                        justifyContent: 'start',
                    }}
                    value="cycle:asc"
                >
                    Cycle Ascending
                </MenuItem>
                <MenuItem
                    sx={{
                        display: 'flex',
                        padding: '0.25em 1em',
                        justifyContent: 'start',
                    }}
                    value="deadline:desc"
                >
                    Deadline
                </MenuItem>
                <MenuItem
                    sx={{
                        display: 'flex',
                        padding: '0.25em 1em',
                        justifyContent: 'start',
                    }}
                    value="deadline:asc"
                >
                    Deadline Ascending
                </MenuItem>
                <MenuItem
                    sx={{
                        display: 'flex',
                        padding: '0.25em 1em',
                        justifyContent: 'start',
                    }}
                    value="descriptionTruncated:desc"
                >
                    Description
                </MenuItem>
                <MenuItem
                    sx={{
                        display: 'flex',
                        padding: '0.25em 1em',
                        justifyContent: 'start',
                    }}
                    value="descriptionTruncated:asc"
                >
                    Description Ascending
                </MenuItem>
                <MenuItem
                    sx={{
                        display: 'flex',
                        padding: '0.25em 1em',
                        justifyContent: 'start',
                    }}
                    value="procedure:desc"
                >
                    Procedure
                </MenuItem>
                <MenuItem
                    sx={{
                        display: 'flex',
                        padding: '0.25em 1em',
                        justifyContent: 'start',
                    }}
                    value="procedure:asc"
                >
                    Procedure Ascending
                </MenuItem>
                <MenuItem
                    sx={{
                        display: 'flex',
                        padding: '0.25em 1em',
                        justifyContent: 'start',
                    }}
                    value="published:desc"
                >
                    Published
                </MenuItem>
                <MenuItem
                    sx={{
                        display: 'flex',
                        padding: '0.25em 1em',
                        justifyContent: 'start',
                    }}
                    value="published:asc"
                >
                    Published Ascending
                </MenuItem>
                <MenuItem
                    sx={{
                        display: 'flex',
                        padding: '0.25em 1em',
                        justifyContent: 'start',
                    }}
                    value="status:desc"
                >
                    Status
                </MenuItem>
                <MenuItem
                    sx={{
                        display: 'flex',
                        padding: '0.25em 1em',
                        justifyContent: 'start',
                    }}
                    value="status:asc"
                >
                    Status Ascending
                </MenuItem>
                <MenuItem
                    sx={{
                        display: 'flex',
                        padding: '0.25em 1em',
                        justifyContent: 'start',
                    }}
                    value="value:desc"
                >
                    Value
                </MenuItem>
                <MenuItem
                    sx={{
                        display: 'flex',
                        padding: '0.25em 1em',
                        justifyContent: 'start',
                    }}
                    value="value:asc"
                >
                    Value Ascending
                </MenuItem>
            </Select>
        </div>
    );
};

export default memo(TenderSort, isEqual);
