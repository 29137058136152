import { Box } from '@mui/material';
import isEqual from 'lodash/isEqual';
import React, { memo } from 'react';

const TenderUnselected: React.FunctionComponent = () => {
    return (
        <Box
            sx={{
                display: 'flex',
                flex: 1,
                alignItems: 'center',
                justifyContent: 'center',
                padding: '2em 1em',
                textAlign: 'center',
            }}
        >
            <h1 style={{ fontSize: '1.5em', fontWeight: 400 }}>Search tenders and select to view more details</h1>
        </Box>
    );
};

export default memo(TenderUnselected, isEqual);
