import { useForm } from 'react-hook-form';
import React, { memo, useState } from 'react';
import FormStatus, { Message } from './FormStatus';
import { useContact } from '../../services/ContactService';
import Box from '@mui/material/Box';
import { Button, TextField } from '@mui/material';
import isEqual from 'lodash/isEqual';

interface ContactFormData {
    name: string;
    telephone: string;
    email: string;
    message: string;
}

const ContactForm = () => {
    const { register, handleSubmit, reset } = useForm<ContactFormData>();
    const [messages, setMessages] = useState<Message[]>([]);
    const [errors, setErrors] = useState<Error[]>([]);
    const contact = useContact(reset, setMessages, setErrors);
    return (
        <>
            <FormStatus messages={messages} errors={errors} sx={{ paddingBottom: 2, paddingTop: 1 }} />
            <form onSubmit={handleSubmit((data: ContactFormData) => contact(data.name, data.telephone, data.email, data.message))}>
                <Box sx={{ display: 'flex' }}>
                    <TextField
                        id="name"
                        fullWidth
                        color="primary"
                        placeholder="Your name..."
                        type="text"
                        required
                        sx={{ marginRight: '1em' }}
                        {...register('name')}
                        InputProps={{
                            sx: {
                                color: 'white',
                                backgroundColor: '#1976d2',
                                '& input + fieldset': {
                                    borderColor: 'white !important',
                                },
                            },
                        }}
                    />
                    <TextField
                        id="telephone"
                        fullWidth
                        color="primary"
                        placeholder="Your telephone..."
                        type="tel"
                        required
                        sx={{ marginRight: '1em' }}
                        {...register('telephone')}
                        InputProps={{
                            sx: {
                                color: 'white',
                                backgroundColor: '#1976d2',
                                '& input + fieldset': {
                                    borderColor: 'white !important',
                                },
                            },
                        }}
                    />
                    <TextField
                        id="email"
                        fullWidth
                        color="primary"
                        placeholder="Your email..."
                        type="email"
                        required
                        {...register('email')}
                        InputProps={{
                            sx: {
                                color: 'white',
                                backgroundColor: '#1976d2',
                                '& input + fieldset': {
                                    borderColor: 'white !important',
                                },
                            },
                        }}
                    />
                </Box>
                <Box sx={{ display: 'flex' }} pt={2}>
                    <TextField
                        id="message"
                        fullWidth
                        color="primary"
                        type="text"
                        multiline
                        required
                        maxRows={4}
                        placeholder="Message..."
                        {...register('message')}
                        InputProps={{
                            sx: {
                                color: 'white',
                                backgroundColor: '#1976d2',
                                '& fieldset': {
                                    borderColor: 'white !important',
                                },
                            },
                        }}
                    />
                </Box>
                <Box pt={2} pb={4}>
                    <Button
                        id="contact"
                        variant="contained"
                        size="large"
                        type="submit"
                        disableElevation
                        sx={{
                            border: '1px solid white',
                            textTransform: 'none',
                            fontSize: '1.25em',
                        }}
                    >
                        Send Message
                    </Button>
                </Box>
            </form>
        </>
    );
};

export default memo(ContactForm, isEqual);
