import { useEffect, useState } from 'react';
import { atom, selectorFamily, useRecoilState, useRecoilValueLoadable } from 'recoil';

import { useMatch } from 'react-router-dom';
import { Tender } from '@codeaim/tender-ni-api';
import { useTenderNiApiSdk } from './services';
import { useDateRangeUrlSingleState, useRangeUrlSingleState, useUrlSingleState, useUrlState } from './QueryService';
import isEqual from 'lodash/isEqual';

type Filters = {
    search?: string;
    sort?: string;
    organisation?: string;
    category?: string;
    evaluation?: string;
    procedure?: string;
    status?: string;
    type?: string;
    value?: [string, string];
    cycle?: [string, string];
    published?: [string, string];
    deadline?: [string, string];
    awarded?: [string, string];
    code?: string[];
    nuts?: string[];
};

const tendersFilterState = atom<Filters>({
    default: undefined,
    key: 'tendersFilterState',
});

export function useTendersFilters() {
    const [search] = useUrlSingleState('search');
    const [sort] = useUrlSingleState('sort');
    const [organisation] = useUrlSingleState('organisation');
    const [category] = useUrlSingleState('category');
    const [evaluation] = useUrlSingleState('evaluation');
    const [procedure] = useUrlSingleState('procedure');
    const [status] = useUrlSingleState('status');
    const [type] = useUrlSingleState('type');
    const [value] = useRangeUrlSingleState('value');
    const [cycle] = useRangeUrlSingleState('cycle');
    const [published] = useDateRangeUrlSingleState('published');
    const [deadline] = useDateRangeUrlSingleState('deadline');
    const [awarded] = useDateRangeUrlSingleState('awarded');
    const [code] = useUrlState('code');
    const [nuts] = useUrlState('nuts');
    const current = {
        search,
        sort,
        organisation,
        category,
        evaluation,
        procedure,
        status,
        type,
        value,
        cycle,
        published,
        deadline,
        awarded,
        code,
        nuts,
    };
    const [values, setValues] = useRecoilState(tendersFilterState);
    useEffect(() => {
        if (!isEqual(current, values)) {
            setValues(current);
        }
    }, [current]);

    return values;
}

const tendersRequest = selectorFamily<{ tenders: Tender[]; next: string | undefined }, { next: string | undefined; filter: Filters | undefined }>({
    get:
        ({ next, filter }) =>
        async () => {
            if (filter) {
                const { code, nuts, published, awarded, deadline, value, cycle, ...rest } = filter ?? {};
                const queryParameters = {
                    next,
                    published: published ? `${published[0]}/${published[1]}` : undefined,
                    awarded: awarded ? `${awarded[0]}/${awarded[1]}` : undefined,
                    deadline: deadline ? `${deadline[0]}/${deadline[1]}` : undefined,
                    value: value ? `${value[0]}:${value[1]}` : undefined,
                    cycle: cycle ? `${cycle[0]}:${cycle[1]}` : undefined,
                    ...rest,
                };
                const multiQueryParameters = { code, nuts };
                const response = await useTenderNiApiSdk().getTenders(queryParameters, multiQueryParameters, {}, {});
                return {
                    tenders: response.result.member,
                    next: response.result.view?.next,
                };
            }
            return {
                tenders: [],
                next: undefined,
            };
        },
    key: 'tendersRequest',
});

export const useTenders = () => {
    const initialNext = useMatch('next/:id')?.params.id;
    const [tenders, setTenders] = useState<Tender[] | undefined>(undefined);
    const filter = useTendersFilters();
    const [next, setNext] = useState(initialNext);
    const [loading, setLoading] = useState(false);
    const [complete, setComplete] = useState(false);
    const response = useRecoilValueLoadable(tendersRequest({ filter, next }));

    useEffect(() => {
        if (response.state === 'hasValue') {
            setTenders((tenders) => {
                const tenderIds = next && tenders ? tenders.map((x) => x.id) : [];
                return [...(next && tenders ? tenders : []), ...response.contents.tenders.filter((x) => !tenderIds?.includes(x.id))];
            });
            setComplete(!response.contents.next || response.contents.tenders.length < 1);
        }
        setLoading(response.state === 'loading');
    }, [response]);

    useEffect(() => {
        setNext(initialNext);
        setComplete(false);
        setLoading(false);
    }, [filter]);

    return { tenders, getNext: () => setNext(response.contents.next), next: response.contents.next, loading, complete };
};
