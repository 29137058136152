import { useNavigate } from 'react-router-dom';
import { selectorFamily, useRecoilValueLoadable } from 'recoil';
import { useTenderNiApiSdk } from './services';
import { Tender } from '@codeaim/tender-ni-api';

const tenderRequest = selectorFamily<Tender, number>({
    get: (tenderId) => async () => {
        const response = await useTenderNiApiSdk().getTender({ id: String(tenderId) });
        return response.result;
    },
    key: 'tenderRequest',
});

export const useTender = (tenderId: number) => useRecoilValueLoadable(tenderRequest(tenderId));

export const useSelectTender = () => {
    const navigate = useNavigate();
    return async (tenderId: number | undefined) => {
        if (tenderId) navigate(`/tender/${tenderId}${window.location.search}`);
        else navigate(`/${window.location.search}`);
    };
};
