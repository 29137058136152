import { AppBar, Box, Theme, Toolbar } from '@mui/material';
import isEqual from 'lodash/isEqual';
import React, { memo } from 'react';
import { NavLink } from 'react-router-dom';
import { SxProps } from '@mui/material';

import logo from '../assets/image/logo.png';
import logoMedium from '../assets/image/logo-medium.png';
import logoLarge from '../assets/image/logo-large.png';

type HeaderProps = {
    sx?: SxProps<Theme>;
};

const Header = ({ sx, children }: React.PropsWithChildren<HeaderProps>) => {
    return (
        <AppBar
            sx={{
                backgroundColor: (t) => t.palette.primary.main,
                flexShrink: 0,
                position: 'sticky',
                boxShadow: 'none',
                ...sx,
            }}
        >
            <Toolbar>
                <Box component={NavLink} to="/" sx={{ display: 'flex' }}>
                    <Box component="img" src={logo} alt="Tender NI logo" srcSet={`${logo} 1x, ${logoMedium} 2x, ${logoLarge} 4x `} sx={{ maxHeight: 46, height: 46, width: 295 }} />
                </Box>
                {children}
            </Toolbar>
        </AppBar>
    );
};

export default memo(Header, isEqual);
