import { faAward, faBusinessTime, faCoins, faList, faHandsUsd, faMoneyBillWave, faNewspaper, faRedo, faTrophyAlt } from '@fortawesome/pro-light-svg-icons';
import { faCircle } from '@fortawesome/pro-thin-svg-icons';
import { faSearch } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import * as colors from '@mui/material/colors';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import isEqual from 'lodash/isEqual';
import { DateTime } from 'luxon';
import React, { memo } from 'react';
import { Helmet } from 'react-helmet';
import { useMatch } from 'react-router-dom';
import { helmetJsonLdProp } from 'react-schemaorg';
import { JobPosting } from 'schema-dts';

import { useShowSearch, useToggleSearch } from '../../services/NavigationService';
import { useSelectTender, useTender } from '../../services/TenderService';
import { getCategoryBadge, getTenderIcon } from '../TenderSelect/TenderItem';

function getEvaluationIcon(evaluation: string) {
    switch (evaluation) {
        case 'Best Price-Quality Ratio': {
            return faTrophyAlt;
        }
        case 'Highest Price': {
            return faHandsUsd;
        }
        case 'Price/Cost Effectiveness': {
            return faMoneyBillWave;
        }
        default: {
            return faList;
        }
    }
}

function getEvaluationColor(evaluation: string) {
    switch (evaluation) {
        case 'Best Price-Quality Ratio': {
            return colors.orange[700];
        }
        case 'Highest Price': {
            return colors.deepPurple[600];
        }
        case 'Price/Cost Effectiveness': {
            return colors.lightBlue[600];
        }
        default: {
            return colors.grey[400];
        }
    }
}

function getEvaluationBadge(evaluation: string) {
    const title = `Evaluation: ${evaluation}`;
    const color = getEvaluationColor(evaluation);
    return (
        <span className="fa-stack fa-1x">
            <FontAwesomeIcon icon={faCircle} color={color} className="fa-stack-2x" title={title} />
            <FontAwesomeIcon icon={getEvaluationIcon(evaluation)} color={color} className="fa-stack-1x" title={title} />
        </span>
    );
}

const TenderView: React.FunctionComponent = () => {
    const selectedTenderId = useMatch('/tender/:id')?.params.id!;
    const tender = useTender(Number(selectedTenderId));
    const toggleSearch = useToggleSearch();
    const showSearch = useShowSearch();
    const selectTender = useSelectTender();

    if (tender.state === 'loading') {
        return (
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    flex: 1,
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        fontSize: '1.5em',
                    }}
                >
                    <CircularProgress />
                    <Box sx={{ paddingLeft: '1em' }}>Loading...</Box>
                </Box>
            </Box>
        );
    }

    if (tender.state === 'hasError') {
        return (
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    flex: 1,
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        fontSize: '1.5em',
                    }}
                >
                    <Box sx={{ paddingLeft: '1em' }}>Tender not found</Box>
                </Box>
            </Box>
        );
    }

    return tender.state === 'hasValue' ? (
        <Box
            sx={{
                display: 'flex',
                flex: 1,
                flexDirection: 'column',
                padding: '1.25em 1em',
            }}
        >
            <Helmet
                script={[
                    helmetJsonLdProp<JobPosting>({
                        '@context': 'https://schema.org',
                        '@type': 'JobPosting',
                        title: tender.contents.title,
                        description: tender.contents.description,
                        datePosted: tender.contents.published,
                        employmentType: tender.contents.type,
                        hiringOrganization: {
                            '@type': 'Organization',
                            name: tender.contents.ca,
                        },
                        ...(tender.contents.contact && {
                            applicationContact: {
                                '@type': 'ContactPoint',
                                email: tender.contents.contact,
                            },
                        }),
                        jobLocation: {
                            '@type': 'Place',
                            address: {
                                '@type': 'PostalAddress',
                                addressCountry: 'Northern Ireland',
                            },
                            branchCode: tender.contents.nuts,
                        },
                        industry: tender.contents.category,
                        validThrough: tender.contents.deadline,
                        url: `https://tender-ni.com/tender/${tender.contents.id}`,
                        baseSalary: tender.contents.value,
                        estimatedSalary: tender.contents.value,
                    }),
                ]}
            >
                <title>Tender Northern Ireland - {tender.contents.title}</title>
                <meta name="description" content={`Tender opportunity in Northern Ireland. Tender ${tender.contents.id} - ${tender.contents.descriptionTruncated}`} />
                <link rel="canonical" href={`https://tender-ni.com/tender/${tender.contents.id}`} />
                <meta property="og:type" content="article" />
                <meta property="og:title" content={`Tender Northern Ireland - ${tender.contents.title}`} />
                <meta property="og:description" content={`Tender opportunity in Northern Ireland. Tender ${tender.contents.id} - ${tender.contents.descriptionTruncated}`} />
                <meta property="og:url" content={`https://tender-ni.com/tender/${tender.contents.id}`} />
            </Helmet>
            <Box sx={{ width: '100%' }}>
                <Box sx={{ flexDirection: 'row', marginBottom: '1.25em' }}>
                    <Button
                        onClick={() => toggleSearch()}
                        sx={{
                            display: { xs: 'none', md: 'inline-flex !important' },
                            marginRight: '1em',
                            padding: '0.825em',
                        }}
                        size="large"
                        aria-label="Toggle search"
                        variant={showSearch ? 'contained' : 'outlined'}
                    >
                        <FontAwesomeIcon icon={faSearch} />
                    </Button>
                    <Button
                        onClick={() => {
                            if (!showSearch) {
                                toggleSearch();
                            }
                            selectTender(undefined);
                        }}
                        size="large"
                        sx={{ backgroundColor: (t) => t.palette.background.paper }}
                        variant="outlined"
                    >
                        Back
                    </Button>
                </Box>
            </Box>
            <Paper
                sx={{
                    maxWidth: '60em',
                    margin: '0 auto',
                }}
                elevation={3}
            >
                <Box
                    sx={{
                        padding: '1em',
                        display: 'flex',
                    }}
                >
                    <Box
                        sx={{
                            flex: 1,
                            fontSize: '1em',
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: '1.5rem',
                                fontWeight: 400,
                            }}
                            gutterBottom
                            variant="h1"
                        >
                            {tender.contents.title}
                        </Typography>
                        <Typography gutterBottom>
                            {tender.contents.type ? `${tender.contents.type} - ` : ''}
                            {tender.contents.ca}
                        </Typography>
                        {tender.contents.category && <Typography gutterBottom>{tender.contents.category}</Typography>}
                        {tender.contents.published && (
                            <Typography gutterBottom>
                                <Box component={FontAwesomeIcon} icon={faNewspaper} sx={{ marginRight: '0.75em' }} />
                                {DateTime.fromISO(tender.contents.published).toHTTP()}
                            </Typography>
                        )}
                        {tender.contents.deadline && (
                            <Typography
                                gutterBottom
                                sx={{
                                    color: DateTime.fromISO(tender.contents.deadline) < DateTime.local() ? colors.red[800] : 'initial',
                                }}
                            >
                                <Box component={FontAwesomeIcon} sx={{ marginRight: '0.75em' }} icon={faBusinessTime} />
                                {DateTime.fromISO(tender.contents.deadline).toHTTP()}
                            </Typography>
                        )}
                        {tender.contents.awarded && (
                            <Typography gutterBottom>
                                <Box component={FontAwesomeIcon} sx={{ marginRight: '0.75em' }} icon={faAward} />
                                {DateTime.fromISO(tender.contents.awarded).toHTTP()}
                            </Typography>
                        )}
                        {tender.contents.value !== 0 && (
                            <Typography gutterBottom>
                                <Box component={FontAwesomeIcon} sx={{ marginRight: '0.75em' }} icon={faCoins} />
                                {tender.contents.value}
                            </Typography>
                        )}
                        {tender.contents.cycle !== 1 && (
                            <Typography gutterBottom>
                                <Box component={FontAwesomeIcon} sx={{ marginRight: '0.75em' }} icon={faRedo} />
                                {tender.contents.cycle}
                            </Typography>
                        )}
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            '& span': {
                                margin: '0.25em 0em 0.25em 0.5em',
                                fontSize: '1.25em',
                            },
                        }}
                    >
                        {getTenderIcon(tender.contents.status, tender.contents.procedure)}
                        {tender.contents.category && getCategoryBadge(tender.contents.category)}
                        {tender.contents.evaluation && getEvaluationBadge(tender.contents.evaluation)}
                    </Box>
                </Box>
                <Divider />
                <Typography
                    sx={{
                        paddingLeft: '1em',
                        paddingTop: '1em',
                        paddingRight: '1em',
                        '&.MuiTypography-root': {
                            fontWeight: 600,
                        },
                    }}
                >
                    Description
                </Typography>
                <Typography
                    sx={{
                        paddingLeft: '1em',
                        paddingTop: '1em',
                        paddingRight: '1em',
                    }}
                >
                    {tender.contents.description}
                </Typography>
                {tender.contents.contact && (
                    <>
                        <Typography
                            sx={{
                                paddingLeft: '1em',
                                paddingTop: '1em',
                                paddingRight: '1em',
                                '&.MuiTypography-root': {
                                    fontWeight: 600,
                                },
                            }}
                        >
                            Contact
                        </Typography>
                        <Typography
                            sx={{
                                paddingLeft: '1em',
                                paddingTop: '1em',
                                paddingRight: '1em',
                            }}
                        >
                            {tender.contents.contact}
                        </Typography>
                    </>
                )}
                {tender.contents.nuts && tender.contents.nuts.length > 0 && (
                    <Typography
                        sx={{
                            paddingLeft: '1em',
                            paddingTop: '1em',
                            paddingRight: '1em',
                            '&.MuiTypography-root': {
                                fontWeight: 600,
                            },
                        }}
                    >
                        Nuts
                    </Typography>
                )}
                {tender.contents.nuts && tender.contents.nuts.length > 0 && (
                    <Typography
                        sx={{
                            paddingLeft: '1em',
                            paddingTop: '1em',
                            paddingRight: '1em',
                        }}
                    >
                        {tender.contents.nuts.join(', ')}
                    </Typography>
                )}
                {tender.contents.codes && tender.contents.codes.length > 0 && (
                    <Typography
                        sx={{
                            paddingLeft: '1em',
                            paddingTop: '1em',
                            paddingRight: '1em',
                            '&.MuiTypography-root': {
                                fontWeight: 600,
                            },
                        }}
                    >
                        Codes
                    </Typography>
                )}
                {tender.contents.codes &&
                    tender.contents.codes.length > 0 &&
                    tender.contents.codes.map((code) => (
                        <Typography
                            key={code}
                            sx={{
                                padding: '1em 1em 0 1em',
                            }}
                        >
                            {code}
                        </Typography>
                    ))}
                <Divider sx={{ paddingTop: '1em' }} />
                <Box
                    sx={{
                        padding: '1em',
                        '& a': {
                            textDecoration: 'none',
                        },
                    }}
                >
                    <Typography gutterBottom>More information, tender actions & source:</Typography>
                    <a target="_blank" rel="noreferrer" href={`https://etendersni.gov.uk/epps/cft/prepareViewCfTWS.do?resourceId=${tender.contents.id}`}>
                        <Button variant="outlined">Source</Button>
                    </a>
                </Box>
            </Paper>

            <Box sx={{ paddingBottom: '2em' }} />
        </Box>
    ) : (
        <div />
    );
};

export default memo(TenderView, isEqual);
