import { Box } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import * as colors from '@mui/material/colors';
import isEqual from 'lodash/isEqual';
import React, { memo } from 'react';

const TendersLoading: React.FunctionComponent = () => {
    return (
        <Box
            sx={{
                backgroundColor: colors.grey[300],
                display: 'flex',
                justifyContent: 'center',
                flex: 1,
                padding: '1em',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    fontSize: '1.5em',
                }}
            >
                <CircularProgress />
                <Box
                    sx={{
                        paddingLeft: '1em',
                    }}
                >
                    Loading...
                </Box>
            </Box>
        </Box>
    );
};

export default memo(TendersLoading, isEqual);
