import { useEffect } from 'react';
import { onCLS, onFCP, onTTFB, onLCP, onFID, onINP } from 'web-vitals';
import { Metric } from 'web-vitals/src/types';
import ReactGA from 'react-ga4';

const sendWebVitals = ({ name, value }: Metric) => {
    ReactGA.event({
        category: 'Web Vitals',
        action: name,
        value
    });
};

export const useAnalytics = (trackingId: string) => {
    ReactGA.initialize(trackingId);
    useEffect(() => {
        onCLS(sendWebVitals);
        onFCP(sendWebVitals);
        onTTFB(sendWebVitals);
        onLCP(sendWebVitals);
        onFID(sendWebVitals);
        onINP(sendWebVitals);
    }, []);
};
