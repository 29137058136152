import { Box } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import isEqual from 'lodash/isEqual';
import React, { memo } from 'react';

const Loading: React.FunctionComponent = () => {
    return (
        <Box
            sx={{
                paddingTop: '1em',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                flex: 1,
            }}
        >
            <Box sx={{ display: 'flex', alignItems: 'center', fontSize: '1.5em' }}>
                <CircularProgress />
                <Box sx={{ paddingLeft: '1em' }}>Loading...</Box>
            </Box>
        </Box>
    );
};

export default memo(Loading, isEqual);
