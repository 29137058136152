import { faSearch } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import InputAdornment from '@mui/material/InputAdornment';
import InputBase from '@mui/material/InputBase';
import isEqual from 'lodash/isEqual';
import React, { memo, useCallback, useState } from 'react';

import { useQueryParameter, useUpdateQueryParameter } from '../../services/QueryService';
import debounce from 'lodash/debounce';
import { useLocation } from 'react-router-dom';

const TenderSearch: React.FunctionComponent = () => {
    const location = useLocation();
    const search = useQueryParameter('search')[0] ?? '';
    const [currentSearch, setCurrentSearch] = useState<string>(search);
    const updateSearch = useCallback(debounce(useUpdateQueryParameter('search'), 500, { trailing: true }), [location]);
    return (
        <InputBase
            sx={{
                padding: '1em',
                borderColor: (t) => t.palette.grey[300],
                borderWidth: '0 0 1px 1px',
                borderStyle: 'solid',
            }}
            placeholder="Search tenders"
            value={currentSearch}
            inputProps={{
                'aria-label': 'Search tenders',
                style: { paddingLeft: '0.5em' },
            }}
            onChange={(event) => {
                setCurrentSearch(event.target.value);
                updateSearch([event.target.value]);
            }}
            startAdornment={
                <InputAdornment position="start">
                    <FontAwesomeIcon icon={faSearch} />
                </InputAdornment>
            }
            fullWidth
        />
    );
};

export default memo(TenderSearch, isEqual);
