import axios from 'axios';
import { Caller, TenderNiApiSdk } from '@codeaim/tender-ni-api';
import qs from 'query-string';
import { apiBaseUrl } from '../Environment';

export function useTenderNiApiSdk() {
    return new TenderNiApiSdk(unauthenticatedAxios(`${apiBaseUrl}`));
}

export function unauthenticatedAxios(url: string): Caller {
    return {
        call: async (method: any, resource: any, path: string, body: any, pathParameters: any, queryParameters: any, multiQueryParameters: any, headers: any, config: any) => {
            const result = await axios(url + path, {
                method: method as any,
                data: body,
                paramsSerializer: (params) => qs.stringify(params),
                params: { ...queryParameters, ...multiQueryParameters },
                headers,
                transformResponse: [],
                ...config,
            });
            return {
                statusCode: result.status,
                body: result.data,
                headers: result.headers as any,
            };
        },
    };
}
