import Box from '@mui/material/Box';
import isEqual from 'lodash/isEqual';
import React, { memo } from 'react';
import Header from '../../components/Header';
import { colors, Container, Typography } from '@mui/material';
import ContactForm from './ContactForm';

const About: React.FunctionComponent = () => {
    return (
        <Box sx={{ backgroundColor: colors.blue[800], height: '100%' }}>
            <Header sx={{ boxShadow: 'none' }} />
            <Container maxWidth={'md'} sx={{ padding: '0 4em', backgroundColor: colors.blue[800] }}>
                <Typography
                    sx={{
                        fontSize: '2.5rem',
                        fontWeight: 400,
                        color: 'white',
                    }}
                    pt={4}
                    gutterBottom
                    variant="h1"
                >
                    Our Aim
                </Typography>
                <Typography sx={{ color: 'white', lineHeight: 2 }}>
                    Tender Northern Ireland aims to promote and helps others find tender opportunities in Northern Ireland. Our goal is to make tender opportunities more accessible. We provide
                    information regarding tender opportunities in Northern Ireland and make it easily searchable. Tender Northern Ireland promotes tender opportunities in Northern Ireland across
                    various media platforms. Our hope is that through our efforts more people can avail of tender opportunities, stimulating the local economy through competition.
                </Typography>
                <Typography
                    sx={{
                        fontSize: '2.5rem',
                        fontWeight: 400,
                        color: 'white',
                    }}
                    pt={4}
                    gutterBottom
                    variant="h1"
                >
                    Who Are We
                </Typography>
                <Typography sx={{ color: 'white', lineHeight: 2 }}>
                    Tender Northern Ireland is run by CodeAim Limited a Northern Ireland based software development consultancy. Gerard Downes founder of CodeAim Limited originally created Tender
                    Northern Ireland to help him access tender information. Our hope is that others find it useful.
                </Typography>
                <Typography
                    sx={{
                        fontSize: '2.5rem',
                        fontWeight: 400,
                        color: 'white',
                    }}
                    pt={4}
                    gutterBottom
                    variant="h1"
                >
                    Get In Touch
                </Typography>
                <Typography sx={{ color: 'white', lineHeight: 2 }}>
                    Don't hesitate to get in touch. We are available by e-mail on admin@tender-ni.com. You can also get in touch through our contact form.
                </Typography>
                <ContactForm />
            </Container>
        </Box>
    );
};

export default memo(About, isEqual);
