import isEqual from 'lodash/isEqual';
import React, { memo } from 'react';

import Header from './Header';
import Tenders from './Tenders';
import { useAnalytics } from '../services/AnalyticsService';
import { Box } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSliders as faFilterOn } from '@fortawesome/pro-thin-svg-icons';
import { faSliders as faFilterOff } from '@fortawesome/pro-light-svg-icons';
import { useShowFilter, useToggleFilter } from '../services/NavigationService';

const trackingId = process.env.REACT_APP_TRACKING_ID || 'G-9CVEFX729B';

const Main: React.FunctionComponent = () => {
    useAnalytics(trackingId);
    const showFilter = useShowFilter();
    const toggleFilter = useToggleFilter();
    return (
        <>
            <Header>
                <Box sx={{ display: 'flex', flex: 1, justifyContent: 'flex-end' }}>
                    <Box
                        component={FontAwesomeIcon}
                        sx={{
                            color: (t) => t.palette.background.paper,
                            fontSize: '1.5em',
                            '&:hover': {
                                color: (t) => t.palette.grey[400],
                                cursor: 'pointer',
                            },
                        }}
                        icon={window.navigator.userAgent === 'ReactSnap' || showFilter ? faFilterOn : faFilterOff}
                        onClick={() => toggleFilter()}
                    />
                </Box>
            </Header>
            <Tenders />
        </>
    );
};

export default memo(Main, isEqual);
